var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import './discountsSearch.css';
import { discountStatusName } from 'pages/specialOffer/discountStatusName';
import { useSpecialOfferApi } from 'pages/specialOffer/useSpecialOfferApi';
import UserContext from 'common/userContext';
var generateOptionLabel = function (e) {
    var _a, _b;
    if (e.number.startsWith('OS')) {
        return "".concat(e.number, ", ").concat(UserContext.isFromVCP() ? e.dealerName + ',' : '', " ").concat(e.partyName ? e.partyName : '', " (").concat(e.identificationNumberValue, "), ").concat(discountStatusName(e.status), ", ").concat(e.validTo);
    }
    if (e.number.startsWith('DRS')) {
        return "".concat(e.number, ", ").concat(UserContext.isFromVCP() ? e.dealerName + ',' : '', " ").concat(e.partyName ? e.partyName : '', " (").concat(e.partyIdentificationNumber, "), CON: ").concat(e.car.commonOrderNumber, ", VIN: ").concat((_a = e.car.vin) !== null && _a !== void 0 ? _a : '');
    }
    if (e.number.startsWith('KON')) {
        return "".concat(e.number, ", ").concat(UserContext.isFromVCP() ? e.dealerName + ',' : '', " ").concat(e.partyName ? e.partyName : '', " (").concat(e.identificationNumberValue, "), ").concat(discountStatusName(e.status), ", ").concat(e.validTo);
    }
    if (e.number.startsWith('TI')) {
        return "".concat(e.number, ", ").concat(UserContext.isFromVCP() ? e.dealerName + ',' : '', " ").concat(e.partyName ? e.partyName : '', " (").concat(e.identificationNumberValue, "), CON: ").concat(e.commonOrderNumber, ", VIN: ").concat((_b = e.vin) !== null && _b !== void 0 ? _b : '');
    }
};
export var DiscountSearch = withCancellation(function () {
    var specialOfferApi = useSpecialOfferApi();
    var history = useHistory();
    function mergeLoadOption(inputValue) {
        if (inputValue.length < 3) {
            return;
        }
        if (inputValue.toLowerCase().startsWith('kon/')) {
            return specialOfferApi.searchVCP(inputValue);
        }
        if (inputValue.toLowerCase().startsWith('os/')) {
            return specialOfferApi.search(inputValue);
        }
        if (inputValue.toLowerCase().startsWith('drs/')) {
            return specialOfferApi.searchDRS(inputValue);
        }
        if (inputValue.toLowerCase().startsWith('ti/')) {
            return specialOfferApi.searchTradeIn(inputValue);
        }
        return Promise.allSettled([
            specialOfferApi.searchVCP(inputValue),
            specialOfferApi.search(inputValue),
            specialOfferApi.searchDRS(inputValue),
            specialOfferApi.searchTradeIn(inputValue)
        ]).then(function (_a) {
            var concessionsResult = _a[0], specialOffersResult = _a[1], extraSaleDiscountsResult = _a[2], tradeInsResult = _a[3];
            var concessions = concessionsResult.status === 'fulfilled' ? concessionsResult.value : [];
            var specialOffers = specialOffersResult.status === 'fulfilled' ? specialOffersResult.value : [];
            var extraSaleDiscounts = extraSaleDiscountsResult.status === 'fulfilled' ? extraSaleDiscountsResult.value : [];
            var tradeIns = tradeInsResult.status === 'fulfilled' ? tradeInsResult.value : [];
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], concessions, true), specialOffers, true), extraSaleDiscounts, true), tradeIns, true);
        });
    }
    function getDiscountIdAndOpen(searchParams, paramName, paramValue) {
        searchParams.set(paramName, paramValue);
        history.push('/discounts/search-preview?' + searchParams.toString());
    }
    var specialOfferSelectChangeHandler = function (option) {
        var searchParams = new URLSearchParams();
        if (option.number.startsWith('OS')) {
            getDiscountIdAndOpen(searchParams, 'specialOfferNumber', option.number);
        }
        if (option.number.startsWith('DRS')) {
            getDiscountIdAndOpen(searchParams, 'extraSaleDiscountId', option.id);
        }
        if (option.number.startsWith('KON')) {
            getDiscountIdAndOpen(searchParams, 'concessionId', option.id);
        }
        if (option.number.startsWith('TI')) {
            getDiscountIdAndOpen(searchParams, 'tradeInNumber', option.number);
        }
    };
    return (React.createElement("div", { className: "discountsSearchContainer", "data-testid": "discounts-search-container" },
        React.createElement(AsyncSelect, { value: null, getOptionLabel: function (e) { return generateOptionLabel(e); }, loadOptions: mergeLoadOption, onChange: specialOfferSelectChangeHandler, autoFocus: true, placeholder: 'NIP/PESEL Strony, Nazwa strony, Numer wniosku, VIN, CON, DOIN', noOptionsMessage: function () { return null; } })));
});
