var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApplicantSection } from 'pages/extraSaleDiscounts/applicantSection/ApplicantSection';
import { ViewSection } from 'pages/extraSaleDiscounts/viewSection/ViewSection';
import React, { useState } from 'react';
import './concessionPreview.css';
import { OrderingPartyPreviewSection } from '../orderingPartySection/OrderingPartyPreviewSection';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { ContractSection } from '../contractSection/ContractPreviewSection';
import { VcpDiscountPreviewSection } from '../vcpDiscountSection/VcpDiscountPreviewSection';
import { SettlementSection } from '../settlementSection/SettlementSection';
import { SettlementCorrectionsSection } from '../settlementCorrectionsSection/SettlementCorrectionsSection';
import { SupplementSectionPreview } from '../supplementSection/SupplementSectionPreview';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Spinner } from 'components/spinner/Spinner';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { useHistory, useLocation } from 'react-router-dom';
import UserContext from 'common/userContext';
import { useConcessionPreview } from '../useConcessionPreview';
import { useConcessionApi } from '../useConcessionApi';
import ReactDOM from 'react-dom';
import { AuditDocumentsSection } from 'pages/specialOffer/auditDocumentsSection/AuditDocumentsSection';
import { AuditDocumentationConfig } from 'pages/specialOffer/auditDocumentsSection/AuditDocumentationConfig';
import { OldAuditDocumentationConfig } from '../OldAuditDocumentationConfig';
export var ConcessionPreview = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var history = useHistory();
    var location = useLocation();
    var _b = location.search.split('?'), searchParamsString = _b[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var concessionId = searchParams.get('concessionId');
    var concessionApi = useConcessionApi();
    var _c = useState(), auditDocuments = _c[0], setAuditDocuments = _c[1];
    var _d = useState(), auditDocumentsList = _d[0], setAuditDocumentsList = _d[1];
    var _e = useConcessionPreview({ concessionId: concessionId }), concessionApplication = _e.concessionApplication, partyCars = _e.partyCars, organizationData = _e.organizationData, applicantCard = _e.applicantCard;
    var applicantSectionData = organizationData && applicantCard && concessionApplication && {
        organizationName: organizationData.organizationName,
        applicantName: applicantCard.name,
        applicantEmail: applicantCard.email,
        applicantPhone: applicantCard.phoneNumber,
        applicationNumber: concessionApplication.number
    };
    var orderingPartyPreviewData = concessionApplication && partyCars && {
        party: concessionApplication.party,
        partyCars: partyCars,
        offerRequestDate: concessionApplication.offerRequestDate,
        offerRequestEvidences: concessionApplication.offerRequestEvidences
    };
    var contractPreviewData = concessionApplication && {
        funding: concessionApplication.funding,
        order: concessionApplication.order
    };
    var vcpDiscountSectionData = concessionApplication && {
        validFrom: concessionApplication.validFrom,
        validTo: concessionApplication.validTo,
        discount: {
            discountGroups: concessionApplication.discountGroups,
            secondDiscountGroupGlobalMajorAccounts: concessionApplication.secondDiscountGroupGlobalMajorAccounts,
            professionGroups: concessionApplication.professionGroups,
            specialOfferTypes: concessionApplication.specialOfferTypes,
            specialOfferGlobalMajorAccounts: concessionApplication.specialOfferGlobalMajorAccounts,
        },
        discountGroupQualificationEvidences: concessionApplication.discountGroupQualificationEvidences,
        changes: concessionApplication.changes,
    };
    var settlementSectionData = concessionApplication && {
        settlements: concessionApplication.settlements,
    };
    var settlementsWithCorrectionApplicationSubmitted = settlementSectionData && settlementSectionData.settlements.filter(function (settlement) { return settlement.correctionApplication.status === 'SUBMITTED'; });
    var supplementSectionData = concessionApplication && {
        supplements: concessionApplication.supplements
    };
    function onCloseClick() {
        if (location.pathname === '/discounts/search-preview') {
            history.push('/discounts/search-preview');
            return;
        }
    }
    function supplementationAllowed() {
        return concessionApplication && concessionApplication.status === 'ACCEPTED';
    }
    function onSupplementApplicationClick() {
        if (supplementationAllowed()) {
            window.location.href = "#/concessions/supplement-application/".concat(concessionId, "?fromNewSearch");
        }
    }
    function onEditingValidationClick() {
        if (concessionsExpirationDateEditingAllowed()) {
            window.location.href = "#/concessions/valid-to-edition/".concat(concessionId, "?fromNewSearch");
        }
    }
    function onSettlementApplicationAcceptanceClick() {
        if (settlementApprovingAllowed()) {
            window.location.href = "#/concessions/settlement-acceptance/".concat(concessionId, "?fromNewSearch");
        }
    }
    function isVCPSales() {
        return UserContext.hasAnyRole('VCP_SALES');
    }
    function settlementApprovingAllowed() {
        return concessionApplication && concessionApplication.settlements && concessionApplication.settlements.some(function (settlement) { return settlement.status === 'SUBMITTED'; });
    }
    function concessionsExpirationDateEditingAllowed() {
        return concessionApplication && concessionApplication.status === 'ACCEPTED';
    }
    function isCustomerServiceUser() {
        return UserContext.hasAnyRole('CUSTOMER_SERVICE');
    }
    function concessionApplicationSubmitted() {
        return concessionApplication && concessionApplication.status === 'SUBMITTED';
    }
    function onConcessionApplicationAcceptanceClick() {
        if (concessionApplicationSubmitted()) {
            window.location.href = "#/concessions/acceptance/".concat(concessionId, "?fromNewSearch");
        }
    }
    function getAuditDocuments(offerId) {
        return __awaiter(this, void 0, void 0, function () {
            var auditDocumentsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isCustomerServiceUser()) {
                            return [2 /*return*/];
                        }
                        setStatus('pending');
                        return [4 /*yield*/, concessionApi.getAuditDocuments(offerId)];
                    case 1:
                        auditDocumentsResponse = _a.sent();
                        if (auditDocumentsResponse && auditDocumentsResponse.status === 200) {
                            ReactDOM.unstable_batchedUpdates(function () {
                                setAuditDocuments(auditDocumentsResponse.data);
                                setAuditDocumentsList('DISCOUNT_GROUP_BELONGING' in auditDocumentsResponse.data.attachments ? AuditDocumentationConfig() : OldAuditDocumentationConfig());
                                setStatus('success');
                            });
                        }
                        else {
                            setStatus('error');
                            console.error('Błąd pobierania dokumentów audytowych');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function clearAuditDocumentsState() {
        setAuditDocuments(null);
    }
    var isSupplementationDisabled = UserContext.isFromVCP() || UserContext.hasAnyRole('CUSTOMER_SERVICE');
    return (React.createElement("div", { className: 'concessionWrapper' },
        React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: 'applicant-section-wrapper' },
            React.createElement(ApplicantSection, { applicantSectionData: applicantSectionData })),
        React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: 'client-section' },
            React.createElement(OrderingPartyPreviewSection, { orderingPartyPreviewData: orderingPartyPreviewData })),
        React.createElement(ViewSection, { title: 'kontrakt', dataTestid: 'contract-section-wrapper' },
            React.createElement(ContractSection, { contractPreviewData: contractPreviewData })),
        React.createElement(ViewSection, { title: 'rabat vcp', dataTestid: 'vcp-discount-section' },
            React.createElement(VcpDiscountPreviewSection, { vcpDiscountSectionData: vcpDiscountSectionData })),
        React.createElement(AccordionViewSection.Root, { defaultValue: ['audit-documents'] },
            React.createElement(AccordionViewSection.Item, { value: 'settlement-section' },
                React.createElement(AccordionViewSection.Trigger, null, "rozliczenie"),
                React.createElement(AccordionViewSection.Content, null,
                    React.createElement(SettlementSection, { settlementSectionData: settlementSectionData, onSettlementClick: function (offerId) { return getAuditDocuments(offerId); } }))),
            auditDocuments && !isCustomerServiceUser() &&
                React.createElement(AccordionViewSection.Item, { value: 'audit-documents' },
                    React.createElement(AccordionViewSection.Trigger, null, "dokumenty audytowe"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(AuditDocumentsSection, { auditDocuments: auditDocuments, clearAuditDocumentsState: clearAuditDocumentsState, auditDocumentsList: auditDocumentsList }))),
            settlementSectionData && settlementsWithCorrectionApplicationSubmitted.length > 0 &&
                React.createElement(AccordionViewSection.Item, { value: 'settlement-correction-application-section' },
                    React.createElement(AccordionViewSection.Trigger, null, "wnioski o korekt\u0119 rozliczenia"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(SettlementCorrectionsSection, { settlementCorrectionsSectionData: settlementsWithCorrectionApplicationSubmitted, isVCPSales: isVCPSales(), concessionId: concessionId }))),
            supplementSectionData && supplementSectionData.supplements.length > 0 &&
                React.createElement(AccordionViewSection.Item, { value: 'supplement-application-section' },
                    React.createElement(AccordionViewSection.Trigger, null, "wnioski o rozszerzenie rabatu VCP"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(SupplementSectionPreview, { supplementSectionData: supplementSectionData, isVCPSales: isVCPSales(), concessionId: concessionId })))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "concessionButtonsWrapper", "data-testid": "concession-buttons-wrapper" },
            supplementationAllowed() && !UserContext.isFromVCP() &&
                React.createElement("div", { className: "conncessionButton" },
                    React.createElement(Button, { variant: 'secondary', type: "button", onClick: onSupplementApplicationClick, disabled: isSupplementationDisabled }, "wniosek o rozszerzenie rabatu VCP")),
            isVCPSales() && !concessionApplicationSubmitted() &&
                React.createElement("div", { className: 'concessionButtonsForVCP' },
                    React.createElement("div", { className: 'conncessionButton' },
                        React.createElement(Button, { variant: 'secondary', type: "button", onClick: function () { return onSettlementApplicationAcceptanceClick(); }, disabled: !settlementApprovingAllowed() }, "przejd\u017A do rozliczenia")),
                    React.createElement("div", { className: 'conncessionButton' },
                        React.createElement(Button, { variant: 'secondary', type: "button", onClick: function () { return onEditingValidationClick(); }, disabled: !concessionsExpirationDateEditingAllowed() }, "edytuj termin wa\u017Cno\u015Bci"))),
            isVCPSales() && concessionApplicationSubmitted() &&
                React.createElement("div", { className: 'conncessionButton' },
                    React.createElement(Button, { variant: 'secondary', type: "button", onClick: function () { return onConcessionApplicationAcceptanceClick(); } }, "przejd\u017A do akceptacji")),
            React.createElement("div", { className: "conncessionButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onCloseClick }, "zamknij"))),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
