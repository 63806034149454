import React from 'react';
import { useLocation } from 'react-router-dom';
import { ExtraSaleDiscountPreview } from 'pages/extraSaleDiscounts/extraSaleDiscountPreview/ExtraSaleDiscountPreview';
import { ConcessionPreview } from 'pages/concessions/concessionPreview/ConcessionPreview';
import { DiscountSearch } from './DiscountsSearch.component';
import { SpecialOfferPreview } from 'pages/specialOffer/specialOfferPreview/SpecialOfferPreview';
import { TradeInPreview } from 'pages/tradeIn/tradeInPreview/TradeInPreview';
export function DiscountsSearchPreview() {
    var location = useLocation();
    var _a = location.search.split('?'), searchParamsString = _a[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    var extraSaleDiscountId = searchParams.get('extraSaleDiscountId');
    var concessionId = searchParams.get('concessionId');
    var tradeInNumber = searchParams.get('tradeInNumber');
    return (React.createElement("div", null,
        React.createElement("h2", { className: 'discountsSearchHeader' }, "Wyszukiwarka rabat\u00F3w: VCP, Oferta Specjalna, DRS, Trade-In"),
        React.createElement("div", null,
            React.createElement(DiscountSearch, null)),
        React.createElement("div", null,
            specialOfferNumber && React.createElement(SpecialOfferPreview, { key: specialOfferNumber }),
            extraSaleDiscountId && React.createElement(ExtraSaleDiscountPreview, { key: specialOfferNumber }),
            concessionId && React.createElement(ConcessionPreview, { key: concessionId }),
            tradeInNumber && React.createElement(TradeInPreview, { key: tradeInNumber }))));
}
