// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .discountsSearchContainer {
    margin: 12px 43px 0 43px;
}

#new-dol .discountsSearchHeader {
    font-family: 'Volvo Novum';
    font-weight: 500;
    font-size: 18px;
    color: var(--main-text-color);
    margin-left: 43px;
    margin-top: 32px;
    padding-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/discountsSearch/discountsSearch.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".discountsSearchContainer {\n    margin: 12px 43px 0 43px;\n}\n\n.discountsSearchHeader {\n    font-family: 'Volvo Novum';\n    font-weight: 500;\n    font-size: 18px;\n    color: var(--main-text-color);\n    margin-left: 43px;\n    margin-top: 32px;\n    padding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
